import require from '@/utils/require';
import { network, auto } from '@/config/geteway';

// 运费报价分页
export const fareByPage = function(data = {}) {
  return require({
    url: `${network}/${auto}/offerinfo/findOfferInfoFareByPage`,
    method: 'POST',
    data
  });
};
// 计算订单费用
export const calcOrderCopeReceivable = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/calcOrderCopeReceivable`,
    method: 'POST',
    data
  });
};
// 运费报价详情
export const InfoFare = function(data = {}) {
  return require({
    url: `${network}/${auto}/offerinfo/lookOfferInfoFare`,
    method: 'POST',
    data
  });
};
// 批量生成箱号
export const createOrder = function(data = {}) {
  return require({
    url: `${network}/${auto}/ordercase/createOrderCaseList`,
    method: 'POST',
    data
  });
};
// 查询收货地址
export const addressByPage = function(data = {}) {
  return require({
    url: `${network}/${auto}/deliveryaddress/findDeliveryAddressByPage`,
    method: 'POST',
    data
  });
};
// 批量生成提货单号
export const createOrderNum = function(data = []) {
  return require({
    url: `${network}/${auto}/orderpick/createOrderPickList`,
    method: 'POST',
    data
  });
};
// 提交
export const submitOrder = function(data = []) {
  return require({
    url: `${network}/${auto}/orderinfo/submitOrderInfo`,
    method: 'POST',
    data
  });
};
// 暂存
export const noteOrder = function(data = []) {
  return require({
    url: `${network}/${auto}/orderinfo/temporaryStorageOrderInfo`,
    method: 'POST',
    data
  });
};
// 编辑回填
export const lookEditOrderInfo = function(data = []) {
  return require({
    url: `${network}/${auto}/orderinfo/lookEditOrderInfo`,
    method: 'POST',
    data
  });
};// 查询订单清关文件
export const getOrderClearanceFiles = function(data = []) {
  return require({
    url: `${network}/${auto}/orderinfo/getOrderClearanceFiles`,
    method: 'POST',
    data
  });
};
// 查询订单报关文件
export const getOrderCustomsFiles = function(data = []) {
  return require({
    url: `${network}/${auto}/orderinfo/getOrderCustomsFiles`,
    method: 'POST',
    data
  });
};
export const importExcelByNewWisdom = function(data = {}) {
  return require({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: `${network}/${auto}/orderinfo/importExcelByNewWisdom`,
    method: 'POST',
    data
  });
};

export const calcOrderCaseList = function(data = {}) {
  return require({
    url: `${network}/${auto}/ordercase/calcOrderCaseList`,
    method: 'POST',
    data
  });
};
// 查询结算方式list
export const findClearingWay = function(data = {}) {
  return require({
    url: `${network}/${auto}/clearingway/findClearingWay`,
    method: 'POST',
    data
  });
};
// 查询订单商品装箱信息list
export const findOrderCaseByOrderId = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/findOrderCaseByOrderId`,
    method: 'POST',
    data
  });
};

// 根据商品计算装箱单(从商品->箱子,平均分配)
export const shopToCase = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/shopToCase`,
    method: 'POST',
    data
  });
};

// 根据装箱单计算商品(从箱子->商品，汇总统计)
export const caseToShop = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/caseToShop`,
    method: 'POST',
    data
  });
};

// 删除计算的订单（特殊状态的订单）
export const delCalcOrderByCustomerId = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/delCalcOrderByCustomerId`,
    method: 'POST',
    data
  });
};

// 上传导入箱子
export const importCreateOrderCaseList = function(data = {}) {
  return require({
    url: `${network}/${auto}/ordercase/importCreateOrderCaseList`,
    method: 'POST',
    data
  });
};

// 上传导入箱子
export const importCreateOrderCaseListNew = function(data = {}) {
  return require({
    url: `${network}/${auto}/ordercase/importCreateOrderCaseListNew`,
    method: 'POST',
    data
  });
};

