<template>
  <div class="home">
    <div class="title">
      <p class="c-box">
        <i class="el-icon-price-tag" />
        <span @click="goto('/home')">首页</span> > <span class="">运价查询</span>
      </p>
    </div>
    <div class="c-box">
      <div>
        <div class="transport">
          <div style="display: flex">
            <p class="label">目的国家</p>
            <div style="flex: 1">
              <span
                v-for="item in frontArr"
                :key="item.id"
                onselectstart="return false"
                :class="{'button':true,'select':forData.stateCode==item.code}"
                style="margin-top: 10px"
                @click="setCode(item.code,'stateCode')"
              >{{ item.name }}</span>
              <span
                v-for="item in behindArr"
                v-show="isFold"
                :key="item.id"
                onselectstart="return false"
                :class="{'button':true,'select':forData.stateCode==item.code}"
                style="margin-top: 10px"
                @click="setCode(item.code,'stateCode')"
              >{{ item.name }}</span>
            </div>
            <div class="arrow">
              <i v-show="isFold==false" class="el-icon-arrow-down" @click="isFold=!isFold" />
              <i v-show="isFold==true" class="el-icon-arrow-up" @click="isFold=!isFold" />
            </div>
          </div>
          <div>
            <p class="label">目的仓库</p>
            <el-select v-model="forData.arriveWarehouseId" clearable filterable placeholder="请选择" class="se36 border">
              <el-option
                v-for="item in Warehouse"
                :key="item.id"
                :label="item.warehouseCode + ' -- ' + item.warehouseName"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div class="transport">
          <div>
            <p class="label">运输方式</p>
            <span v-for="item in Freight" :key="item.id" onselectstart="return false" :class="{'button':true,'select':forData.tid==item.id}" @click="setCode(item.id,'tid')">{{ item.codeName }}</span>
          </div>
          <div>
            <p class="label">开船日期</p>
            <el-date-picker
              v-model="forData.sailTime"
              class="se36 border"
              type="date"
              value-format="yyyy-MM-dd HH:mm:ss"
              clearable
            />
          </div>
        </div>
        <div class="transport">
          <div>
            <p class="label">柜型</p>
            <span v-for="item in Types" :key="item.id" onselectstart="return false" :class="{'button':true,'select':forData.types==item.id}" @click="setCode(item.id,'types')">{{ item.name }}</span>
          </div>
          <div>
            <p class="label">服务名称</p>
            <el-input v-model="forData.names" style="width: 220px" class="se36 border" />
            <el-button type="primary" icon="el-icon-search" size="normal" style="margin-left: 20px; padding: 8px 16px" @click="fareByPage">查询</el-button>
          </div>
        </div>
      </div>
      <div v-loading="listLoading" class="content">
        <el-table
          class="freightRate-table"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            align="center"
            prop="names"
            label="服务名称"
            width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.names">{{ scope.row.names }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="shipRoute"
            label="起运港/目的港"
          >
            <template #default="{ row }">
              <span>{{ row.startShipmentName }}</span>
              <span class="route_line" />
              <span>{{ row.destinationPortName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="60"
            label="方式"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.types==1">整柜</span>
              <span v-else>拼箱</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="shipRoute"
            label="目的仓库"
            width="200"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- <div style="max-height:64px;overflow:auto;"> -->
              <span>{{ scope.row.frontWarehouseCode || scope.row.arriveWarehouseCodes }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="sailTime"
            width="130"
            label="开船日期"
          >
            <template #default="{ row }">
              <span style="font-weight: bold; color: red">{{ row.sailTime ? row.sailTime.substr(0, 10) : null }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="cutOffTime"
            width="100"
            label="截单日期"
          >
            <template #default="{ row }">
              {{ row.cutOffTime ? row.cutOffTime.substr(0, 10) : null }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="voyageDay"
            width="60"
            label="航程"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.voyageDay">{{ scope.row.voyageDay }}天</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="100"
            label="区间"
          >
            <template slot-scope="scope">
              <p v-for="(item,i) in scope.row.oceanFeeList" :key="i">
                <span v-if="item">{{ item.specificationName }}</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            width="50"
            label="金额"
          >
            <template slot-scope="scope">
              <p v-for="(item,i) in scope.row.oceanFeeList" :key="i">
                <span v-if="item">{{ item.unitPrice }}</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            width="50"
            label="单位"
          >
            <template slot-scope="scope">
              <p v-for="(item,i) in scope.row.oceanFeeList" :key="i">
                <span v-show="item.unit==1">KG</span>
                <span v-show="item.unit==2">票</span>
                <span v-show="item.unit==3">个</span>
                <span v-show="item.unit==4">柜</span>
                <span v-show="item.unit==5">方</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            width="120"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button type="primary" size="mini" style="width: 80px; margin-bottom: 5px" @click="goto(`/freightRate/goOrder?id=${scope.row.id}&oId=${scope.row.id}${scope.row.frontWarehouseCode ? '&arrivelWH=' + scope.row.frontWarehouseCode : ''}`)">去下单</el-button>
              <el-button type="primary" size="mini" style="width: 80px; margin-left: 0" @click="upload(scope.row.id)">上传箱单</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="fenye"
          background
          layout="prev, pager, next"
          :current-page="forData.pageNum"
          :page-size="forData.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      title="上传箱单"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="closeModal"
    >
      <el-radio v-model="win_type" :label="1">旧系统Excel模板</el-radio>
      <el-radio v-model="win_type" :label="2">新箱单模板</el-radio>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModal">取 消</el-button>
        <el-upload
          class="upload1"
          :action="actionUrl"
          :with-credentials="true"
          :show-file-list="false"
          :before-upload="judgeType"
          :on-success="success"
          :on-error="fail"
          :headers="{offerInfoId}"
        >
          <el-button class="button1">上 传</el-button>
        </el-upload>
        <!--         <el-button type="primary">确 定</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findCountry, findTransportWay, findfabWarehouse, findQuotationTypeBy } from '@/api/select.js';
import { fareByPage } from '@/api/freight.js';
export default {
  name: 'Home',
  props: {},
  data: function() {
    return {
      isFold: false,
      frontArr: [],
      behindArr: [],
      // 弹窗相关
      dialogVisible: false,
      offerInfoId: '',
      win_type: 1,
      Country: [],
      Freight: [],
      Warehouse: [],
      Types: [],
      forData: {/* types:'1',*/},
      total: 0,
      tableData: [],
      action: process.env.VUE_APP_BASEURL + '/jayudMallWeb/orderinfo/importExcelByNewWisdom',
      listLoading: false
    };
  },
  computed: {
    actionUrl() {
      let url = process.env.VUE_APP_BASEURL;
      if (this.win_type === 1) {
        url += '/jayudMallWeb/orderinfo/importExcelByNewWisdom';
      } else if (this.win_type === 2) {
        url += '/jayudMallWeb/orderinfo/orderImport';
      }
      return url;
    },
    frontWarehouseCodea() {
      return this.forData.arriveWarehouseId ? this.Warehouse.find(item => item.id === this.forData.arriveWarehouseId).warehouseCode : null;
    }
  },
  watch: {
    forData: function(val) {
      return this.forData.stateCode == val;
    }
  },
  created() {
    this.init();
    this.fareByPage();
  },
  mounted() {

  },
  methods: {
    init() {
      this.findCountry();
      this.findTransportWay();
      this.$forceUpdate();
      this.findfabWarehouse();
    },
    setCode(id, name) { // 选择国家 运输方式 柜型
      if (id == this.forData[name]) {
        this.forData[name] = null;
        this.forData = { ...this.forData };
      } else {
        this.forData[name] = id;
        this.forData = { ...this.forData };
      }
      // this.fareByPage();
    },
    findCountry() { // 查询国家
      if (this.$store.state.country.length) {
        this.Country = this.$store.state.country;
        this.frontArr = this.Country.slice(0, 5);
        this.behindArr = this.Country.slice(5);
      } else {
        findCountry().then(result => {
          if (result.status == 200) {
            const data = result.data;
            this.Country = data.data;
            this.frontArr = this.Country.slice(0, 5);
            this.behindArr = this.Country.slice(5);
            // this.forData.stateCode=data.data[0].code
            this.$store.dispatch('newCountry', data.data);
          }
        });
      }
      findQuotationTypeBy().then(result => {
        if (result.status == 200) {
          this.Types = result.data.data;
        }
      });
    },
    findTransportWay() { // 查询运输方式
      if (this.$store.state.freight.length) {
        this.Freight = this.$store.state.freight;
      } else {
        findTransportWay().then(result => {
          if (result.status == 200) {
            const data = result.data;
            this.Freight = data.data;
            // this.forData.tid=data.data[0].idCode
            this.$store.dispatch('newFreight', data.data);
          }
        });
      }
    },
    findfabWarehouse() { // 查询目的仓库
      findfabWarehouse().then(result => {
        if (result.status == 200) {
          const data = result.data;
          this.Warehouse = data.data;
        }
      });
    },
    fareByPage() { // 查询分页数据
      this.listLoading = true;
      let flag = false;
      if (this.forData.arriveWarehouseId) {
        flag = this.Warehouse.find(item => item.id === this.forData.arriveWarehouseId).warehouseCode;
      }
      fareByPage(this.forData).then(result => {
        if (result.data.code == 0) {
          const data = result.data;
          data.data.list.forEach(item => {
            item.frontWarehouseCode = flag;
          });
          this.tableData = data.data.list;
          this.total = data.data.total;
        }
        this.listLoading = false;
      }).catch(error => {
        try {
          if (error.response) {
            this.$message.error({
              message: error.response.data.msg
            });
          } else {
            this.$message.error({
              message: '请求失败'
            });
          }
        } catch (err) {
          this.$message.error({
            message: err
          });
        }
        this.listLoading = false;
      });
    },
    handleCurrentChange(val) { // 切换页数
      this.forData.pageNum = val;
      this.fareByPage();
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    judgeType(file) {
      if (file.name.indexOf('.xlsx') == -1 && file.name.indexOf('.xls') == -1) {
        this.$message({
          message: "Uploading file's format is error, please check.",
          type: 'error',
          duration: 1000
        });
        return false;
      }
    },
    success(response) {
      if (response.code == 0) {
        this.closeModal();
        this.$message({
          message: response.msg,
          type: 'success',
          duration: 1000
        });
        this.goto('/freightRate/goOrder?id=' + this.offerInfoId);
        this.$store.dispatch('newFile', response.data);
        // this.$router.push({
        //   path:'/freightRate/goOrder',
        //   query:{
        //     id:this.offerInfoId
        //   },
        //   params:response.data
        // });
      } else if (response.code == -1) {
        this.$message({
          message: response.msg,
          type: 'error',
          duration: 1000
        });
      } else if (response.code == 400 && this.win_type === 2) {
        this.$store.commit('setPackingList', response.data);
        this.$message({
          type: 'error',
          message: response.msg,
          duration: 1000,
          onClose: () => {
            this.$router.push({
              name: 'commodityAddCommodity',
              params: {
                import: true
              }
            });
          }
        });
      }
    },
    // 文件上传失败信息
    fail(error) {
      this.$message({
        message: error.msg,
        type: 'error',
        duration: 1000
      });
    },
    upload(id) { // 上传箱单
      this.offerInfoId = id;
      this.openModal();
    },
    openModal() { // 打开弹窗
      this.dialogVisible = true;
    },
    closeModal() { // 关闭弹窗
      this.dialogVisible = false;
    }
  }
};

</script>

<style scoped>
.title {
  height: 30px;
  line-height: 30px;
  background: #F0F0F0;
  margin: 0;
  font-size: 12px;
  color: #999
}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title span:nth-child(3){color:#333}
.country{margin-top:50px;}
.button {
  display: inline-block;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 15px;
}
.label {
  font-size: 12px;
  display: inline-block;
  margin: 10px 20px 0 0;
  line-height: 36px;
}
.arrow{
  margin: 6px 19px 0 0;
  font-size:26px;
  font-weight: 900;
  color: #507eff;
}
.select{background:#FC6C3F;color:#fff;border-color:#FC6C3F !important;}
.transport{overflow:hidden}
.transport>div{width:50%;float:left;}
.transport .label {
  min-width: 50px;
}
.content{margin-top:20px; width: 100%;}
.goodname{border-bottom: 1px solid #008cd6;color: #008cd6;cursor:pointer;}
.type1{
  margin: 0;
  margin-top: 10px;
  border-radius: 30px;
  padding: 5px 10px;
  color: #fff;
  background: #507eff;
  border-color: #507eff;
}
.upload1{
  display:inline-block;
  margin-left:10px;
}
.upload1 .button1{background:#507eff;color:#fff}
.dialog-footer{
  text-align:center;
}
.route_line {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 2px;
  background-color: #507eff;
  margin: 0 15px 0 5px;
  position: relative;
}
.route_line::after {
  content: '';
  position: absolute;
  right: -10px;
  bottom: 0;
  border-left: 10px solid #507eff;
  border-top: 7px solid transparent;
}
</style>
